import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Layout from '../../../components/Layout';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TextWithDivider from '../../../components/TextWithDivider';
import { Paths } from '../../../routes/paths';
import OrganisationCard from '../components/OrganisationCard';
import { getAllEnterprise } from '../../../helper/backendHelper/enterprise';
import Message from '../../../components/Message';
import { EventContext } from '../../../components/EventContextProvider';
import PlaceholderImage from '../../../components/PlaceholderImage';
import Board from '../../../../src/assets/images/board.svg';
import {
  resendCredentialsPoc,
  createPoc,
} from '../../../helper/backendHelper/poc'; // Assuming you have this function
import Tooltip from '@mui/material/Tooltip';
import { EmailOutlined } from '@material-ui/icons';
import DialogWrapper from '../../../components/DialogWrapper';

const OrganisationList = () => {
  let { events } = useContext(EventContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [enterprises, setEnterprises] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pocForm, setPocForm] = useState({ pocName: '', pocEmail: '' }); // POC Form state
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null); // Store selected organization id

  useEffect(() => {
    fetchAllEnterprise();
  }, []);

  const fetchAllEnterprise = async () => {
    try {
      setLoading(true);
      const enterprise = await getAllEnterprise();
      setEnterprises(enterprise?.payload?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  const handleResendCredentials = async (pocId) => {
    try {
      setLoading(true);
      const response = await resendCredentialsPoc({ pocId });
      Message.success(response.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  const handlePocFormChange = (e) => {
    const { name, value } = e.target;
    setPocForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitPoc = async () => {
    if (!pocForm.pocName || !pocForm.pocEmail || !selectedOrganizationId) {
      Message.error('Please fill all fields and select an organization');
      return;
    }

    try {
      setLoading(true);
      const response = await createPoc({
        enterpriseId: selectedOrganizationId,
        name: pocForm.pocName,
        email: pocForm.pocEmail,
        role: 'poc',
      });

      Message.success(response.message);
      setLoading(false);
      toggleDialog();
      fetchAllEnterprise();
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  const toggleDialog = () => {
    setIsDialogOpen((prev) => !prev);
    setPocForm({ pocName: '', pocEmail: '' });
  };

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <DialogWrapper
          title="Add Point of Contact"
          open={isDialogOpen}
          loading={false}
          submitButtonTitle="Create POC"
          handleSubmit={handleSubmitPoc} // Bind form submission
          cancelButtonTitle="Cancel"
          handleClose={toggleDialog}>
          <div style={{ width: '500px' }} className="p-4">
            <Typography variant="h6">Enter POC Details</Typography>
            <TextField
              label="POC Name"
              fullWidth
              className="my-3"
              name="pocName"
              value={pocForm.pocName}
              onChange={handlePocFormChange}
            />
            <TextField
              label="POC Email"
              fullWidth
              className="my-3"
              name="pocEmail"
              value={pocForm.pocEmail}
              onChange={handlePocFormChange}
            />
          </div>
        </DialogWrapper>
        <div className="mx-5">
          <Grid container justifyContent="space-between" className="p-4">
            <Grid>
              <TextWithDivider>
                <Typography variant="h3" color="textPrimary">
                  Organisations
                </Typography>
              </TextWithDivider>
            </Grid>
            <Button
              onClick={() => {
                history.push(Paths.organization);
              }}
              disableElevation
              startIcon={<AddCircleOutline />}
              color="primary"
              variant="contained">
              Add New Organisation
            </Button>
          </Grid>
          {!enterprises.length ? (
            <div>
              <PlaceholderImage
                image={Board}
                messsage="No records available"
                width="300px"
              />
            </div>
          ) : (
            <>
              <Typography className="p-4" variant="h3">
                Organisation Details
              </Typography>
              {enterprises.map((el, idx) => (
                <Accordion key={idx}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${idx}-content`}
                    id={`panel${idx}-header`}
                    style={{ width: '100%' }}>
                    <OrganisationCard
                      organizationId={el._id}
                      yearOfIncorporation={el.yearOfIncorporation}
                      name={el.name}
                      location={el.location}
                      val={el}
                      eventsInProgress={el.noOfInProgressCollectionEvent}
                      eventsCompleted={el.noOfCompletedCollectionEvent}
                      totalEvents={el.totalEvents}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {el.pocs?.length ? (
                      <TableContainer component={Paper}>
                        <div className="d-flex justify-content-between align-items-center pe-4">
                          <Typography className="p-4" variant="h3">
                            POC User Details
                          </Typography>
                          <Button
                            onClick={() => {
                              setSelectedOrganizationId(el._id); // Set the selected organization
                              toggleDialog(); // Open the dialog to create POC
                            }}
                            disableElevation
                            startIcon={<AddCircleOutline />}
                            color="primary"
                            variant="contained"
                            style={{ height: '40px' }}>
                            Add POC
                          </Button>
                        </div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <strong>Name</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Email</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Phone</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Designation</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Action</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {el.pocs.map((poc, pocIdx) => (
                              <TableRow key={pocIdx}>
                                <TableCell>{poc.name || 'N/A'}</TableCell>
                                <TableCell>{poc.email || 'N/A'}</TableCell>
                                <TableCell>{poc.phone || 'N/A'}</TableCell>
                                <TableCell>
                                  {poc.designation || 'N/A'}
                                </TableCell>
                                <TableCell>
                                  <Tooltip
                                    title="Reset the password of POC"
                                    arrow>
                                    <EmailOutlined
                                      onClick={() => {
                                        handleResendCredentials(poc._id);
                                      }}
                                    />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No Point of Contact data available.
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
        </div>
      </SecondaryLayout>
    </Layout>
  );
};

export default OrganisationList;
