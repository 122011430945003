import React, { useEffect, useState } from 'react';
import SecondaryLayout from '../../../components/SecondaryLayout';
import Layout from '../../../components/Layout';
import { Button, IconButton, TextField } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import TextWithDivider from '../../../components/TextWithDivider';
import Typography from '@material-ui/core/Typography';
import { Form } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CommonForm from '../../../components/CommonForm';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getAuthToken } from '../../../helper/auth_helper';
import { removeEmptyKey } from '../../../utils/objectUtils';
import { useHistory } from 'react-router';
import employeForm from '../../../assets/FormControl/employeArray';
import { getManagerById } from '../../../helper/backendHelper/manager';
import { createTeam, getAllTeam } from '../../../helper/backendHelper/team';
import {
  createEmployee,
  getEmployeeById,
  updateEmployee,
} from '../../../helper/backendHelper/employee';
import Message from '../../../components/Message';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DialogWrapper from '../../../components/DialogWrapper';

function AddEditEmployees() {
  const history = useHistory();
  const location = useLocation();
  const collectionEvent = location.state?.collectionEvent;
  const params = useParams();
  const formElements = new employeForm();
  const [employeeFormArray, setEmployeeFormArray] = useState(
    formElements.getEmployeFormArray()
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [enterpriseId, setEnterpriseId] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [teamName, setTeamName] = useState(null);

  const { type, collectionEventId, id } = params;
  useEffect(() => {
    if (type && collectionEventId) {
      if (type === 'edit') {
        getEmployee(id);
        setIsEdit(true);
      } else {
        getEnterpriseByPoc();
        setIsEdit(false);
      }
      getTeam(collectionEventId);
    } else {
      history.goBack();
    }
  }, []);

  // get team for dropdwon
  const getTeam = async (collectionEventId) => {
    try {
      const res = await getAllTeam(collectionEventId);
      if (res.success) {
        const data = res.payload.data.map((x) => {
          return { label: x.teamName, value: x._id };
        });
        const selectData = employeeFormArray.map((x) => {
          if (x.name === 'teamId') {
            x.options = data;
          }
          return x;
        });
        setEmployeeFormArray(selectData);
      }
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };

  // Get Employee for Update
  const getEmployee = async (id) => {
    try {
      const res = await getEmployeeById(id);
      if (res.success) {
        setEmployee(res.payload);
        setinputValue(res.payload);
        if (res.payload.isCollectionStarted) {
          const modifiedForm = employeeFormArray.map(formField => {
            if (formField.type === "email") {
              formField.disabled = true
            }
            return formField;
          })
          setEmployeeFormArray(modifiedForm)
        }
        setIsLoading(false);
      }
    } catch (error) {
    }
  };

  // initialize input value
  const setinputValue = (updateArray) => {
    employeeFormArray.forEach((element, index) => {
      if (element.type === 'date') {
        inputGroup.setFieldValue(
          element.name,
          updateArray[element.name],
          false
        );
      } else {
        inputGroup.setFieldValue(
          element.name,
          updateArray[element.name],
          false
        );
      }
    });
  };

  // Get enterprise
  const getEnterpriseByPoc = async () => {
    try {
      const res = await getManagerById(JSON.parse(getAuthToken()).managerId);
      if (res.success) {
        setEnterpriseId(res?.payload?.enterpriseId);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.response?.data?.message || "");
    }
  };

  // Create formik form
  const inputGroup = useFormik({
    initialValues: employeeFormArray.reduce((acc, value) => {
      // create initialValues object
      acc[value.name] = '';
      return acc;
    }, {}),
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
      employeeCode: Yup.string().required('Please enter your employee code '),
      employeeName: Yup.string().required('Please enter your employee name '),
      shift: Yup.string().required('Please Select shift '),
      employeeType: Yup.string().required('Please Select shift '),
      department: Yup.string().required('Please Select Department '),
    }),
    onSubmit: (values) => {
      if (values.employeeType === 'identical' && values.teamId === '') {
        Message.error('Please Select Team');
        return;
      }
      isEdit
        ? updateEmployeeById(values, employee._id)
        : createManager(values);
    },
  });

  // Create Employee
  const createManager = async (values) => {
    try {
      const req = {
        ...removeEmptyKey(values),
        collectionEventId: collectionEvent?._id,
        enterpriseId: enterpriseId,
      };
      const res = await createEmployee(req);
      if (res.success) {
        Message.success('Employee created successfully');
        history.goBack();
      }
    } catch (error) {
      Message.error('Please enter valid employee data');
    }
  };

  // Update Employee
  const updateEmployeeById = async (values, id) => {
    try {
      const res = await updateEmployee(id, { ...removeEmptyKey(values) });

      if (res.success) {
        Message.success(res.message);
        history.goBack();
      }
    } catch (error) {

      Message.error(error.response?.data?.message || "");
    }
  };

  // function call by function name
  const FunctionMaping = {};
  const functionCallByName = (functionName, event, formArray) => {
    try {
      FunctionMaping[functionName](event, formArray);
    } catch (error) {
    }
  };

  const handleSubmitTeam = async () => {
    if (!teamName) {
      Message.error('Please enter the Team name.');
      return;
    }
    if (collectionEventId) {
      try {
        const response = await createTeam({
          teamName,
          collectionEventId: collectionEventId
        });
        console.log('collectionEventId::: ', collectionEventId);
        getTeam(collectionEventId);
        handleClose();
        Message.success(response.message);
      } catch (error) {
        Message.error(error?.response?.data?.message);
      }
    }
  };

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  return (
    <Layout loading={isLoading}>
      {isLoading ? (
        <></>
      ) : (
        <SecondaryLayout>
          <div className="p-5">
            <Grid alignItems="center" className=" d-flex" item>
              <IconButton
                onClick={() => {
                  history.goBack();
                }}>
                <ArrowBack />
              </IconButton>
              <Grid container justifyContent="space-between">
                <Grid>
                  <TextWithDivider>
                    <Typography variant="h3" color="textPrimary">
                      {isEdit ? 'Edit ' : 'Add '}Employee
                    </Typography>
                  </TextWithDivider>
                </Grid>
                <Grid sx={{ marginRight: '50px' }}>
                  <TextWithDivider>
                    <Typography variant="h3" color="textPrimary">
                      Collection Event
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {collectionEvent.name}
                    </Typography>
                  </TextWithDivider>
                </Grid>
              </Grid>
            </Grid>

            <Form className="px-3 pt-3" onSubmit={inputGroup.handleSubmit}>
              <CommonForm
                FormArray={employeeFormArray}
                FormikGroup={inputGroup}
                functionCallByName={functionCallByName}
                handleOpen={handleOpen}
                isAddTeamFieldInDropDown={true}
              />
              <div className="text-center mt-4">
                <Button
                  className="mt-3"
                  disableElevation
                  color="primary"
                  type="submit"
                  variant="contained">
                  {isEdit ? 'Update' : 'Create'} Employee
                </Button>
              </div>
            </Form>
          </div>
        </SecondaryLayout>
      )}
      <DialogWrapper
        open={isOpen}
        handleClose={handleClose}
        title={'Add your team'}
        cancelButtonTitle="Close"
        dialogId="alert-errored-users"
        handleSubmit={handleSubmitTeam}
      >
        <TextField
          label="Team Name"
          fullWidth
          name="teamName"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </DialogWrapper>
    </Layout>
  );
}

export default AddEditEmployees;
