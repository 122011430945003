import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TableWrapper from '../components/TableWrapper';
import useFetch from '../../../hooks/useFetch';
import DialogWrapper from '../../../components/DialogWrapper';
import { EventContext } from '../../../components/EventContextProvider';
import Message from '../../../components/Message';
import { useHistory } from 'react-router';
import {
  createManager,
  deleteManagerById,
  getAllManager,
  resendCredentialsManager,
} from '../../../helper/backendHelper/manager';
import PocUploadSurface from '../components/UploadSuface';
import { loginManager } from '../../../helper/backendHelper/sendEmails';
import { getEnterpriseDataById } from '../../../helper/backendHelper/poc';
import board from '../../../../src/assets/images/board.svg';
import { downloadFile } from '../../../utils/downloadFile';
import PlaceholderImage from '../../../components/PlaceholderImage';
import axios from 'axios';
import excelSheet from '../../../../src/assets/csv/ManagerTemplate.csv';
import { Paths } from '../../../routes/paths';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { InfoOutlined } from '@material-ui/icons';

const POCManagers = () => {
  const { user } = useContext(AuthContext);
  const { events, setSaveEvents } = useContext(EventContext);
  const [resetSelected, setResetSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [erroredUsers, setErroredUsers] = useState([]);
  const [manager, setManager] = useState([]);
  const [enterPrise, setEnterPrise] = useState({});
  const [save, setSave] = useState(false);
  const [uploadMode, setUploadMode] = useState(false);
  const [event, setEvent] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [tooltip, setTooltip] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchEnterpriseById();
  }, []);

  //* read excel sheet and get data
  useEffect(() => {
    axios
      .get(excelSheet)
      .then((response) => {
        setExcelData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching CSV data:', error);
      });
  }, []);

  //* fetch enterprise data
  const fetchEnterpriseById = async () => {
    try {
      //* get enterprise data
      setLoading(true);
      const getEnterprise = await getEnterpriseDataById(user._id);
      setEnterPrise(getEnterprise?.payload);
      setLoading(false);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  //* fetch all manager
  const fetchManagersByEvents = async (data) => {
    try {
      setLoading(true);
      //* fetch all managers
      const response = await getAllManager(data?._id);
      setManager(response?.payload?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  //* handle start workload collection
  const handleStartWorkLoadCollection = async (selectedIdsMap = []) => {
    if (!enterPrise?.enterpriseId?.isPOCDataAdded) {
      history.push('/poc/settings');
      Message.error(
        'Please add enterprise data in settings before starting workload collection'
      );
      return;
    } else {
      try {
        const response = await loginManager({ ids: selectedIdsMap });
        setSave((preSaved) => !preSaved);
        fetchManagersByEvents(event);

        Message.success(response.message);
      } catch (error) {
        Message.error(error?.response?.data?.message);
      }
    }
  };

  //* uploading manager csv file
  const handleManagerUploads = async (data = []) => {
    // if employee code will be empty, we will not consider that or whole row.
    data = data.filter(row => row?.email !== "");
    data = data.filter(row => row.employeeCode !== "");

    data = data.map((item) => {
      return (item = {
        ...item,
        collectionEventId: event?._id,
        enterpriseId: event?.enterpriseId?._id,
      });
    });

    try {
      setLoading(true);
      const response = await createManager({ managerData: data });
      Message.success(response.message);
      fetchManagersByEvents(event);
      setUploadMode(false);
      setSave((preSaved) => !preSaved);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  //* isActionable => if is not collection started
  const isActionable = (row) => {
    return row.isCollectionStarted !== 'Yes';
  };

  const handleResendCredentials = async (managerId) => {
    try {
      setLoading(true);
      const response = await resendCredentialsManager({ managerId })
      Message.success(response.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  }

  const editManagerById = (Managerid) => {
    history.push({
      pathname: `${Paths.pocManagers}/edit/${event._id}/${Managerid}`,
      state: { collectionEvent: event }
    });
  }

  const deleteManager = async () => {
    const res = await deleteManagerById(deleteId)
    if (res.success) {
      fetchManagersByEvents(event)
      setConfirmDeleteOpen(false);
      setDeleteId(null)
      Message.success('Meneger deleted successfully');
    }
  }

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <Grid container item justifyContent="space-between">
              <Grid>
                <TextWithDivider>
                  <Typography variant="h3" color="textPrimary">
                    Manager
                    <InfoOutlined color="primary" onClick={() => setTooltip(true)} className='ml-2' />
                  </Typography>
                </TextWithDivider>
              </Grid>
              <Grid>
                <Button
                  className="mr-3"
                  variant="contained"
                  size="small"
                  disableElevation
                  disabled={event ? false : true}
                  onClick={() => setUploadMode(true)}
                  color="primary">
                  Upload Manager Data
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  disableElevation
                  disabled={event ? false : true}
                  onClick={() => {
                    history.push({
                      pathname: `${Paths.pocManagers}/add/${event._id}`,
                      state: { collectionEvent: event }
                    });
                  }}
                  color="primary">
                  Add Manager
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} item md={6}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="selectEnterprise">
                    Select Collection Of Events
                  </InputLabel>

                  <Select
                    labelId="selectEnterprise"
                    fullWidth
                    placeholder="Select Enterprise"
                    onChange={(e) => {
                      fetchManagersByEvents(e.target.value);
                      setEvent(e.target.value);
                    }}>
                    {events?.map((item) => (
                      <MenuItem value={item} key={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {manager.length !== 0 && !uploadMode && <div className='border border-2 border-dotted p-2 mt-2 mb-2'>
              <p>
                <b className='text-danger'>Note:</b> To begin collecting workload data, select the desired checkboxes from the manager table, then click on <b>"Start Workload Collection"</b> button.
              </p>
            </div>}
            {manager.length !== 0 && !uploadMode ? (
              <Grid className="mt-3">
                <TableWrapper
                  data={manager}
                  id={'PCEmployeesTable'}
                  tableHeads={[
                    { id: 'email', label: 'Email Id' },
                    { id: 'category', label: 'Category' },
                    { id: 'jobTitle', label: 'Job Title' },
                    {
                      id: 'qualification',
                      label: 'Qualification',
                      align: 'center',
                    },
                    { id: 'location', label: 'Location' },
                    {
                      id: 'isCollectionStarted',
                      label: 'Collection Started',
                      align: 'center',
                    },
                  ]}
                  dataKeys={[
                    { id: 'email' },
                    { id: 'category' },
                    { id: 'jobTitle' },
                    { id: 'qualification', align: 'center' },
                    { id: 'location' },
                    { id: 'isCollectionStarted', align: 'center' },
                  ]}
                  handlePrimaryButtonClick={handleStartWorkLoadCollection}
                  primaryButtonText="Start Workload Collection"
                  checkId={'_id'}
                  isActionable={isActionable}
                  resetSelected={resetSelected}
                  disabled={loading}
                  otherActions={true}
                  resendCredentialsAction={handleResendCredentials}
                  editAction={editManagerById}
                  deleteAction={(id) => {
                    setDeleteId(id)
                    setConfirmDeleteOpen(true)
                  }}
                />
              </Grid>
            ) : null}

            {manager?.length == 0 && !uploadMode ? (
              <PlaceholderImage
                image={board}
                messsage={'No records found please select collection events'}
                width="25%"
              />
            ) : null}

            {uploadMode ? (
              <>
                <PocUploadSurface
                  handleDownloadClick={() => {
                    setLoading(true);
                    downloadFile(excelData, 'Manager-Template.csv');
                    setLoading(false);
                  }}
                  handleBack={() => setUploadMode(false)}
                  isData={Boolean(manager.length)}
                  isReset={save}
                  eventId={event}
                  handleCreateClick={handleManagerUploads}
                />
              </>
            ) : null}
            <DialogWrapper
              open={Boolean(erroredUsers.length)}
              handleClose={() => setErroredUsers([])}
              title={'Errors with following users'}
              cancelButtonTitle="Close"
              dialogId="alert-errored-users">
              <Grid container direction="column">
                {erroredUsers.map((item, index) => (
                  <Typography variant="caption" key={index}>
                    Email: {item.email} at index {item.index}.
                  </Typography>
                ))}
              </Grid>
              <Typography variant="body2" className="mt-3">
                *Please check if email is present and is unique,
                Individual/Identical, Sub Team columns.
              </Typography>
            </DialogWrapper>
          </Grid>

          <ConfirmationDialog
            open={isConfirmDeleteOpen}
            handleClose={() => {
              setDeleteId(null)
              setConfirmDeleteOpen(false);
            }}
            title="Are you sure?"
            message="Are you sure you want to delete manager?"
            handleSubmit={(result) => {
              deleteManager()
            }}
          />
        </Grid>
      </SecondaryLayout>
      <DialogWrapper
        title="User Guide for Manager"
        open={tooltip}
        handleClose={() => setTooltip(false)}>
        <div className='overflow-auto'>
          <div>
            <div>1. Select the <b>Event</b>.</div>
          </div>
          <div className='mt-3'>
            <b>2. Manager Details Upload</b>
            <div className='ml-2'>
              Click the <b>"Managers"</b> icon for each
              event, download the template, fill it in, and upload the CSV file.
            </div>
          </div>
          <div className='mt-3'>
            <b>3. Manager Information Management</b>
            <div className='ml-2'>
              Send credentials, and
              modify, or remove manager profiles as needed.
            </div>
          </div>
          <div className='mt-3'>
            <b>4. Start Workload Collection</b>
            <div className='ml-2'>
              Once manager details are uploaded,
              initiate the workload collection.
            </div>
          </div>
        </div>
      </DialogWrapper>
    </Layout>
  );
};

export default POCManagers;


